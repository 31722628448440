import React ,{ useState } from 'react';
// import encode from '../../utils/encode';
import axios from 'axios';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [formState, setFormState] = useState({
    submitted: false,
    success: null,
  });
  const { name, email, message } = formData;
  const { submitting, success } = formState;

  const handleSubmit = async e => {
    e.preventDefault();

    try {
      const res = await axios.post('/.netlify/functions/contact/', {
        'form-name': 'contact',
        ...formData,
      });
      console.log(res);
      setFormState({ submitting: false });
      setFormState({ success: true });
    } catch (error) {
      setFormState({ submitting: false, success: false });
      console.log(error);
    }
  };

  const handleChange = e => setFormData({ [e.target.name]: e.target.value });

  if (success === null) {
  return (
    <form
      id="contact"
      name="contact"
      method="post"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
      action="/form-success"
    >
      {/* hidden input for netlify bots */}
      <input type="hidden" name="form-name" value="contact" />

      <div>
        <label htmlFor="name">
          お名前<span>*</span>
        </label>
        <input
          id="name"
          name="name"
          type="text"
          value={name}
          onChange={e => handleChange(e)}
          required
        />
      </div>
      <div>
        <label htmlFor="email">
          メールアドレス<span>*</span>
        </label>
        <input
          id="email"
          name="email"
          type="text"
          value={email}
          onChange={e => handleChange(e)}
          required
        />
      </div>
      <div>
        <label htmlFor="message">
          お問い合わせ内容<span>*</span>
        </label>
        <textarea
          id="message"
          name="message"
          type="text"
          value={message}
          onChange={e => handleChange(e)}
          required
        />
      </div>

      {submitting ? (
          <button type="submit">送信中...</button>
        ) : (
      <button type="submit">送信</button>
      )}
    </form>
  );
  } else if (success) {
    return <h3>お問い合わせを受け付けました</h3>;
  } else {
    return <h3>お問い合わせがお受けできませんでした</h3>;
  }
};

export default ContactForm;
