import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../templates/layout';
import ContactWrapper from '../styles/contact/ContactStyles';
import ContactForm from '../components/contact/ContactForm';

const Contact = ({ path, data }) => {
  const seo = {
    page: 'contact',
    title: 'お問い合わせ',
    description: "なんとなく形にならないものをWEBサービスへ",
    url: 'https://about.9ualia.com/contact',
    imgUrl: ``,
    imgAlt: '',
    breadcrumbs: [
      {
        name: 'Contact',
        path: '/contact',
      },
    ],
  };

  return (
    <Layout seo={seo} path={path}>
      <ContactWrapper>
        <h1>お問い合わせ</h1>
        <ContactForm />
      </ContactWrapper>
    </Layout>
  );
};

Contact.propTypes = {
  path: PropTypes.string.isRequired,
};

export default Contact;

export const CONTACT_PAGE_QUERY = graphql`
  query CONTACT_PAGE_QUERY {
    pageImg: file(relativePath: { eq: "page-meta-img.jpg" }) {
      publicURL # used for SEO
    }
  }
`;
